import React from 'react';
import { graphql } from 'gatsby'

import GamesLayout from '../components/GamesLayout';
import useMatchedQuery from '../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentHero from '../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentBlock from '../ContentfulComponents/ContentfulComponentBlock';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "PxSQAgrf9BIY5nE1YbF44"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentHero1: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "M4ccgsp5OU8xQk4PgZYAC"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentBlock2: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "NzlWaJfHmnUt20A1wN59F"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentBlock3: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "4U4ds0b5Se7OmgWgxb5whR"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentBlock4: contentfulComponentBlock (node_locale: {eq: "es-AR"}, contentful_id: {eq: "6SzPwpEFN5vkuV7CWPNbOy"}) {
      
id
name
contentful_id
node_locale
title
direction
cta {
  link
  label
  color
}
reveal {
  type
  props
}
image {
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
    width: 500,
  )  
}
content {
  raw
}

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} withSearch={false} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentSeo0" pathName="/metro-card" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentHero1" pathName="/metro-card" />
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentBlock2" pathName="/metro-card" />
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentBlock3" pathName="/metro-card" />
      
      <ContentfulComponentBlock {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentBlock4" pathName="/metro-card" />
      
    </GamesLayout>
  )
}

export default Page;
